import React, { ReactNode } from 'react'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../form'
import { Input } from '../input'
import { type Control } from 'react-hook-form'

interface IController {
  control: Control<any>
  name: string
  label?: string
  placeholder?: string
  description?: string
  /** Onchange callback function */
  handleChange?: any
  /** Custom click handler */
  handleClick?: React.MouseEventHandler<HTMLInputElement>
  /** Field Disable */
  disabled?: boolean
  /** Field type */
  type?:
    | 'date'
    | 'hidden'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
  inputMode?:
    | 'email'
    | 'tel'
    | 'search'
    | 'text'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined
  /** Handles MaxLength of the field , works only with strings */
  maxlength?: number
  /** Field is required or optional */
  readonly?: boolean
  /** Prefix Icon */
  prefix?: ReactNode
  /** Prefix Icon */
  suffix?: ReactNode
  required?: boolean
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  autoComplete?: React.HTMLInputAutoCompleteAttribute
  className?: string
  containerStyle?: string
}
const InputController = (props: IController) => {
  const {
    control,
    name,
    label,
    placeholder,
    description,
    handleChange,
    handleClick,
    disabled,
    type = 'text',
    maxlength,
    readonly,
    prefix,
    suffix,
    required,
    onFocus,
    onBlur,
    autoComplete,
    className,
    containerStyle,
    inputMode,
  } = props
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, ...field } }) => {
        // Handle NaN values and contolled input https://reactjs.org/link/controlled-components
        field.value = field.value || ''
        return (
          <FormItem>
            {label ? <FormLabel>{label}</FormLabel> : null}
            <FormControl>
              <Input
                {...field}
                onChange={
                  handleChange
                    ? ({ target: { value } }) => {
                        handleChange(value, onChange)
                      }
                    : onChange
                }
                onClick={handleClick}
                type={type}
                inputMode={inputMode}
                maxLength={maxlength}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete={autoComplete}
                readOnly={readonly}
                prefix={prefix}
                suffix={suffix}
                required={required}
                onFocus={onFocus}
                onBlur={onBlur}
                className={className}
                containerStyle={containerStyle}
              />
            </FormControl>
            {description ? <FormDescription>{description}</FormDescription> : null}
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export default InputController
